import { Box, Skeleton, Stack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useEffect } from 'react'
import { useConversationCount } from '../hooks/useConversationCount'

type Props = {
  userUid: string
  onConversationChange?: (count: number) => void
  onLoadingChange?: (isLoading: boolean) => void
}

const MergeUserConversationCount: React.FC<Props> = ({
  userUid,
  onConversationChange,
  onLoadingChange,
}) => {
  const { data: conversationCount, isLoading } = useConversationCount(userUid)

  useEffect(() => {
    if (conversationCount) {
      onConversationChange?.(conversationCount.conversations)
    }
    onLoadingChange?.(isLoading)
  }, [conversationCount, isLoading, onLoadingChange, onConversationChange])

  return (
    <Box mb={2}>
      {isLoading ? (
        <Stack direction="row" align="center">
          <Skeleton h={5} w={16} />
        </Stack>
      ) : (
        <Text fontWeight="500">
          {conversationCount?.conversations} <Trans>Conversations</Trans>
        </Text>
      )}
    </Box>
  )
}

export default MergeUserConversationCount
